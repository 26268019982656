import React from 'react';
import {termsConditions, privacyPolicy} from './config';
const close = require('@components/assets/icons/close.webp');
import './index.css';
const Privacy = ({fix = false}: {fix?: boolean}) => {
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const openTermsHandler = () => {
    setOpenTerms(true);
  };
  const closeTermsHandler = () => {
    setOpenTerms(false);
  };
  const openPrivacyHandler = () => {
    setOpenPrivacy(true);
  };
  const closePrivacyHandler = () => {
    setOpenPrivacy(false);
  };
  return (
    <>
      <div className="privacy">
        By clicking this button you confirm that you have read and agree to the{' '}
        <span onClick={openTermsHandler} className="privacy-link">
          Terms and Conditions
        </span>{' '}
        and{' '}
        <span onClick={openPrivacyHandler} className="privacy-link">
          privacy policy
        </span>{' '}
        of the company and confirm that you are legal age
      </div>
      {openTerms && (
        <div className={`jump-wrap terms-conditions ${fix ? 'fix-wrap' : ''}`}>
          <img
            src={close}
            alt="close"
            onClick={closeTermsHandler}
            className="close-icon"
          />
          <h2 className="privacy-title">Terms and Conditions</h2>
          <main className="terms-conditions-main">
            {termsConditions.map((item, index) => (
              <section key={item.title || index + 1}>
                {!!item.title && <h4>{item.title}</h4>}
                {!!item.ul && (
                  <div>
                    {item.ul.map((tItem, tIndex) => (
                      <div key={tIndex}>
                        <div className="ul-list">
                          <span className="ul-round" />
                          <p className="ul-content">
                            <span className="ul-mark">{tItem.mark}</span>
                            <span>{tItem.content}</span>
                          </p>
                        </div>
                        {!!tItem.cul && (
                          <div className="cul-wrap">
                            {tItem.cul.map((culItem, culIndex) => (
                              <p className="ul-list" key={culIndex}>
                                <span className="ul-round" />
                                <span className="ul-content">{culItem}</span>
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {!!item.content && (
                  <p className="terms-conditions-content">{item.content}</p>
                )}
                {!!item.list && (
                  <>
                    {item.list.map((lItem, lIndex) => (
                      <div key={lIndex}>
                        <p>
                          <span className="list-mark">{lItem.mark}</span>
                          <span>{lItem.content}</span>
                        </p>
                        {!!lItem.list && (
                          <div className="list-indent">
                            {lItem.list.map((li, liIndex) => (
                              <p key={liIndex}>{li}</p>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </section>
            ))}
          </main>
        </div>
      )}
      {openPrivacy && (
        <div className={`jump-wrap terms-conditions ${fix ? 'fix-wrap' : ''}`}>
          <img
            src={close}
            alt="close"
            onClick={closePrivacyHandler}
            className="close-icon"
          />
          <h2 className="privacy-title">Privacy Policy</h2>
          <main className="terms-conditions-main">
            {privacyPolicy.map(item => (
              <section key={item.title}>
                <h4>{item.title}</h4>
                {!!item.content && (
                  <p className="terms-conditions-content">{item.content}</p>
                )}
                {!!item.list && (
                  <>
                    {item.list.map((lItem, lIndex) => (
                      <div className="terms-conditions-list" key={lIndex}>
                        <span className="terms-conditions-list-order">
                          {lIndex + 1}
                        </span>
                        {`. ${lItem}`}
                      </div>
                    ))}
                  </>
                )}
              </section>
            ))}
          </main>
        </div>
      )}
    </>
  );
};

export default Privacy;
